import twitch from '../img/twitch.png';
import twitter from '../img/twitter.png';
import instagram from '../img/instagram.png';
import gmail from '../img/gmail.png';
import discord from '../img/discord.png';

var data = {
       "Twitch":{
          "url":"https://www.twitch.tv/tikie",
          "text":"Twitch",
          "image": twitch
       },
       "Discord":{
          "url": "https://discord.com/invite/zTHKMzZ",
          "text":"Discord",
          "image":discord
       },
       "Twitter":{
          "url":"https://twitter.com/Tikie19",
          "text":"Twitter",
          "image":twitter
       },
       "Instagram":{
          "url":"https://www.instagram.com/tikie19/",
          "text":"Instagram",
          "image":instagram
       },
       "Par email":{
          "url": "",
          "text":"tikiestream@gmail.com",
          "image":gmail
       }
    }


export default data;
