import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Nav from './compo/Nav';

function App() {
  return (

   <div className="App">
        <Nav />
    </div>
  );
}

export default App;
