import React, { Component } from 'react';
import { TwitchEmbed } from 'react-twitch-embed';
//https://www.npmjs.com/package/react-twitch-embed  , TwitchChat, TwitchClip, TwitchPlayer
class Stream extends Component {
  render() {
    return (
        <div>
          <h2>Tikie</h2>
          <div class='twitch-player'>
              <TwitchEmbed
                channel="tikie"
                theme="dark"
                width='100%'
                height='80vh'
                muted
                id="twitch-player"
                allowFullscreen ="true"
                onVideoPause={() => console.log(':(')}
              />
          </div>
        </div>
    );
  }
}

export default Stream;
