import React, { Component } from 'react';
import dataJson from '../data/jsonDataContact.js';
//let data = require('../data/jsonDataContact.js');

class Service extends Component {
  render() {
    var service = []
    Object.keys(dataJson).forEach(function(key) {
      var value = dataJson[key];

      service.push(

        <div class="card">
          <div class="card-body">
            <h5 class="card-title"><img class="slideanim service-img" src={value.image} alt={key}/>
              <a target="_blank" rel="noreferrer" href={value.url}><strong>&nbsp;&nbsp;{value.text}</strong></a>
            </h5>
          </div>
        </div>
      );
    });
    return (
        <div class='service-div'>
            {service}
        </div>
    );
  }
}

export default Service;
