import React, { Component } from 'react';
import Service from './contact-component';

class Contact extends Component {
  render() {

    return (
        <div>
          <h2>Contact</h2>
          <span>Vous pouvez retrouver Tikie ou entrer en contact avec elle sur les réseaux suivants</span>

          <Service></Service>
        </div>
    );
  }
}

export default Contact;
